@tailwind base;
@tailwind components;
@tailwind utilities;

background-image: url('./img/grid.svg');
html {
  @apply font-sans;
}
body {
  margin: 0;
  padding: 0;
  @apply font-sans;
}


h1 {
  @apply text-2xl font-bold;
}

h2 {
  @apply text-xl font-semibold;
}

a,
button {
  @apply transition-colors duration-300;
}

.btn {
  @apply max-w-max px-2 py-1 rounded-md bg-blue-600 hover:bg-blue-800 text-white;
}
.bg-custom-grid {
  background-image: url("./img/grid.svg");
  background-repeat: repeat;
}

/* HOVER-UNDERLINE ANIMATION */
.hover-underline-animation {
  @apply relative inline-block hover:!no-underline;
}

.hover-underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: currentColor;
  transform-origin: bottom center;
  transition: transform 0.25s ease-out, opacity 0.25s ease-out;
}

.hover-underline-animation-wrapper:hover .hover-underline-animation::after,
.hover-underline-animation:hover::after {
  transform: scaleX(1);
  opacity: 1;
  transform-origin: bottom center;
}

.hover-underline-animation-stay {
  @apply relative inline-block hover:!no-underline;
}

.hover-underline-animation-stay::after {
  @apply content-[''] absolute w-full h-0.5 bottom-0 left-0 opacity-100 bg-current;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none !important;
}

/* Ukrywanie natywnej ikony 'eye' w Chrome */
input[type="password"]::-webkit-textfield-decoration-container {
  display: none !important;
}