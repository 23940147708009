.container {
  max-width: 800px;
  margin: 0 auto;
}

.icon-cog {
  transition: transform 0.2s;
}

.icon-cog:hover {
  transform: rotate(90deg);
}

.chart-container {
  display: flex;
  justify-content: center;
}

.stat-box {
  padding: 1rem;
  width: 100%;
  background-color: #f7f7f7;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.button-primary {
  padding: 0.5rem 1.5rem;
  background-color: #38a169;
  color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s;
}

.button-primary:hover {
  background-color: #2f855a;
}

.config-panel {
  position: absolute;
  right: 1rem;
  top: 4rem;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 300px;
}
.chart-container {
  width: 100%;
  max-width: 100vw;
}

.chart {
  width: 100%;
  height: auto;
}