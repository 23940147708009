/* my-react-select.css */

/* Kontener */
.my-react-select__control {
  border: 1px solid #ccc !important;
  border-radius: 0.375rem !important;
  min-height: 38px !important;
  background-color: #fff !important;
  box-shadow: none !important;
}

/* Kontener - stan :hover */
.my-react-select__control:hover {
  border-color: #aaa !important;
}

/* Kontener - stan :focus */
.my-react-select__control--is-focused {
  border-color: #2563eb !important;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.3) !important;
}

/* Kontener wartości (gdzie są chipy) */
.my-react-select__value-container {
  display: flex;
  flex-wrap: wrap !important;   /* pozwala chipom przechodzić do kolejnego wiersza */
  overflow-x: visible !important; /* żeby nie ucinało w poziomie */
  gap: 0.25rem; /* Add gap between chips */
}

/* Sam chip może się zmniejszać w poziomie */
.my-react-select__multi-value {
  display: inline-flex !important;
  flex-shrink: 1 !important;
  max-width: 100% !important;
  /* w razie potrzeby: white-space: normal !important; */
  background-color: #f3f4f6; /* Light gray background for the chip */
  border-radius: 0.25rem;
  padding: 0.125rem 0.5rem; /* Smaller padding */
}


.my-react-select__multi-value__label {
  /* bądź pewien, że to Ci nie psuje zawijania nazwy */
  white-space: normal !important;
  word-break: break-word !important;
  max-width: 100%;
  color: #374151; /* Darker text for the category name */
  margin-right: 0.25rem; /* Spacing between category name and ELO */
}

/* Style for the ELO part */
.my-react-select__multi-value__label span {
    color: #1056e4; /* Gray color for ELO */
    font-size: 0.875rem;
}


/* Przycisk "X" w chipie */
.my-react-select__multi-value__remove {
  border-radius: 0 0.25rem 0.25rem 0 !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
  cursor: pointer !important;
  margin-left: auto;      /* Push "x" to the right */

}

.my-react-select__multi-value__remove:hover {
  background-color: #d1d5db !important;
  color: #000 !important;
}

/* Menu (lista opcji) */
.my-react-select__menu {
  z-index: 10000 !important;
  border-radius: 0.375rem !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
  margin-top: 4px !important;
}

.my-react-select__menu-list {
  max-height: 200px !important;
  overflow-y: auto !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* Pojedyncza opcja w menu */
.my-react-select__option {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.85rem !important;
  cursor: pointer !important;
}

.my-react-select__option--is-focused {
    background-color: transparent !important;
    color: inherit !important;
  }
  
  .my-react-select__option--is-selected {
    background-color: transparent !important;
    color: inherit !important;
  }
/* Input w trybie search */
.my-react-select__input-container {
    margin: 0 !important;
    padding: 0 !important;
     width: 0;
    min-width: 0;
    flex-grow: 1;
}

.my-react-select__input{
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    min-width: 0 !important;
    color: transparent !important;
    caret-color: transparent !important;

}

/* Placeholder */
.my-react-select__placeholder {
    color: #6b7280 !important;
    font-size: 0.875rem !important;
}

/* No options */
.my-react-select__no-options-message {
    font-size: 0.85rem !important;
    color: #6b7280 !important;
    padding: 0.25rem 0.5rem !important;
}

/* USUWANIE GŁÓWNEGO PRZYCISKU CZYSZCZENIA ("X" po prawej) */
.my-react-select__clear-indicator {
    display: none !important;
}